import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

function getImageArray() {
  const count = 10;
  var images = [];
  
  for (var i = 0; i < count; i++) {
    images.push({original: "/gallery/img" + (i + 1) + ".jpg", thumbnail: "/gallery/img" + (i + 1) + "_t.jpg"})
  }

  return images;
}

function Pics() {
  return (
    <ImageGallery 
      items={getImageArray()} 
      thumbnailPosition="bottom" 
      showPlayButton={false} 
      showFullscreenButton={false} 
      infinite={false} 
      showBullets
      showThumbnails={false} />
  )
}

export default Pics