import background from '../img/concerts_bg.jpg'
import aboutImg from '../img/concert.png'

function Concerts() {
  return (
    <div class="about-box" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100%', backgroundPosition: 'center' }}>
      <div class="about-column-text">
        <h2>Anstehende Konzerte</h2>

        <div class="concert">
          <h3>15.12.2024 - 12:00h<br/>Adventskonzert der Jesusfriend in der Jerusalemkirche</h3>
          <a href="https://jesusfriends.de/event/adventskonzert2024" target="BLANK">Link zur Veranstaltung</a>
        </div>

        <div class="concert">
          <h3>18.12.2024 - 19:30h<br/>Open Stage - Marstall Ahrensburg</h3>
          <a href="https://www.marstall-ahrensburg.de/programm/view/open-stage-deine-buehne-deine-performance-1" target="BLANK">Link zur Veranstaltung</a>
        </div>

        <h3>Vergangene Veranstaltungen</h3>        

        <div class="concertOld">
          <h3>15.10.2024 - 19:00h<br/>Offene Bühne bei Weihn Ahrens in Ahrensburg</h3>
        </div>

        <div class="concertOld">
          <h3>30.01.2023 - 19:00h<br/>Offene Bühne bei Weihn Ahrens in Ahrensburg</h3>
        </div>

        <div class="concertOld">
          <h3>26.09.2023 - 19:00h<br/>Offene Bühne bei Weihn Ahrens in Ahrensburg</h3>
        </div>

        <div class="concertOld">        
          <h3>27.06.2023<br/>Offene Bühne bei Weihn Ahrens in Ahrensburg</h3>
        </div>

        <div class="concertOld">        
          <h3>22.06.2023<br/>Private Veranstaltung</h3>
        </div>

        <div class="concertOld">        
          <h3>29.04.2023<br/>Private Veranstaltung</h3>
        </div>

        <div class="concertOld">        
          <h3>18.12.2022<br/>Adventskonzert der Jesusfriend in der Jerusalemkirche</h3>
        </div>

        <div class="concertOld">        
          <h3>02.10.2022<br/>Private Veranstaltung</h3>
        </div>
        
      </div>
      <div class="about-column-image">
        <img src={aboutImg} alt="Küstenspacken machen Musik" class="center" />
      </div>
    </div>
  )
}

export default Concerts

/*
Beispiel eintrag:

<div class="concert">        
          <h3>29.06.2021 - 17:00h <br/> Open Stage - Pelzerhaken</h3>
          <a href="https://www.luebecker-bucht-ostsee.de/veranstaltungsdetails/details/99564/stammdaten/open-stage-pelzerhaken" target="_BLANK" rel="noreferrer">Mehr Infos</a>
        </div>*/