import {
  NavLink
} from "react-router-dom";

function Menu() {
  return (
    <div class="navbar">      
      <div><NavLink to="/contact" activeClassName="page-link-active">Kontakt</NavLink></div>
      <div><NavLink to="/sounds" activeClassName="page-link-active">Mehr</NavLink></div>   
      <div><NavLink to="/pics" activeClassName="page-link-active">Bilder</NavLink></div>      
      <div><NavLink to="/concerts" activeClassName="page-link-active">Termine</NavLink></div>               
      <div><NavLink to="/about" activeClassName="page-link-active">Über uns</NavLink></div>      
      <div><NavLink exact={true} to="/" activeClassName="page-link-active">Home</NavLink></div>
    </div>);
}

export default Menu