import background from '../img/contact_bg.jpg'
import aboutImg from '../img/contact.png'


function Newsletter() {
  return (    
    <div class="about-box" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100%', backgroundPosition: 'center' }}>
      <div class="about-column-text">
      <h2>Vielen Dank!</h2>
      
      <p>Du hast Dich erfolgreich zu unserem Newsletter angemeldet.</p>

      </div>
      <div class="about-column-image">
        <img src={aboutImg} alt="Küstenspacken gucken freundlich" class="center" />
      </div>
    </div>
  )
}

export default Newsletter