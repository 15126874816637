import background from '../img/about_bg.jpg'
import aboutImg from '../img/about.png'

function About() {
  return (
    <div class="about-box" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100%', backgroundPosition: 'center' }}>
      <div class="about-column-text">
        <h2>Das sind die Küstenspacken</h2>
        <p>
          Wir sind davon überzeugt, dass zwei Dinge essentiell für ein glückliches Leben sind: Lachen und
          Musik.
          Gleichzeitig sind das auch sehr persönliche Dinge, so dass wir uns sehr glücklich schätzen in beiden
          Bereichen auf einer Wellenlänge zu sein.
        </p>
        <p>
          Da sich das Glück durch Lachen und Musik vermehrt, wenn man es teilt, ließ es sich nicht vermeiden,
          dass
          wir unsere Häuser verlassen haben, um Euch mit auf eine Reise durch unser musikalisches und
          humorvolles
          Repertoire zu nehmen.
        </p>
        <p>
          Nennt uns Stümper, nennt uns albern, das ist uns ganz egal. Uns ist nur eins wichtig: Spaß auf der
          Bühne zu haben. Und wir
          freuen uns sehr, wenn wir Euch damit gut unterhalten können.
        </p>
        <h3>Booking</h3>
        <p>
          Natürlich könnt Ihr uns buchen, wenn Euch gefällt was wir machen. Wir sind für fast jeden Spaß zu haben, wie z.B.:
          <ul>
            <li>Geburtstage, Hochzeiten, Jubileen</li>
            <li>Eröffnungen, Betriebsfeiern</li>
            <li>Hauskonzerte, Kleinkunstfestivals</li>
            <li>usw.</li>
          </ul>
          Wenn Ihr eine Idee habt, was Ihr mit uns veranstalten wollt, dann schreibt uns einfach an. Wir beißen nicht!
        </p>
      </div>
      <div class="about-column-image">
        <img src={aboutImg} alt="Küstenspacken machen Musik" class="center" />
      </div>
    </div>
  )
}

export default About