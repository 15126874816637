import './App.css';
import './AppMobile.css';
import Menu from './widgets/Menu.js'
import Footer from './widgets/Footer.js'
import Home from './pages/Home.js'
import Pics from './pages/Pics.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import Concerts from './pages/Concerts.js'
import Sounds from './pages/Sounds.js'
import Newsletter from './pages/NewsletterSuccess.js'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import background from "./img/background_home.jpg";

function App() {
  return (
    <Router>
    <div className="App" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100% auto' }}>
      <div>        
        
          <div class="header-part">
            <Menu />
          </div>
          <div class="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/pics">
                <Pics />
              </Route>
              <Route path="/concerts">
                <Concerts />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/sounds">
                <Sounds />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/newsletter-success">
                <Newsletter />
              </Route>
            </Switch>
          </div>
      </div>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
