import background from '../img/contact_bg.jpg'
import aboutImg from '../img/contact.png'


function Contact() {
  return (    
    <div class="about-box" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100%', backgroundPosition: 'center' }}>
      <div class="about-column-text">
      <h2>Kontakt | Impressum</h2>

      <h3>Newsletter</h3>
      <iframe src='https://dashboard.mailerlite.com/forms/51772/55382352902227753/share' width={'600px'} height={'300px'} />

<h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
<p>Leif Ollenstaedt, Thies Rubarth GbR<br />
Gutenbergstra&szlig;e 36a<br />
22525 Hamburg</p>

<h3>Kontakt</h3>
<p>Telefon: 0179 / 399 8 222<br />
E-Mail: kontakt@kuestenspacken.de</p>

<h3>Redaktionell verantwortlich</h3>
<p>Thies Rubarth<br />
Gutenbergstra&szlig;e 36a<br />
22525 Hamburg</p>

<h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<h6 class="text-link">Quelle: <a href="https://www.e-recht24.de/impressum-generator.html" target="_BLANK" rel="noreferrer">https://www.e-recht24.de/impressum-generator.html</a></h6>
      </div>
      <div class="about-column-image">
        <img src={aboutImg} alt="Küstenspacken gucken freundlich" class="center" />
      </div>
    </div>
  )
}

export default Contact